<template>
  <div class="cdt-container">
    <p class="timer-description" v-if="timeLeft > 0">{{ timeLeftString }} </p>
    <p class="timer-description-warning" v-else>{{ $t("orderTimeExpired") }}</p>

    <div class="root">
      <img :src="imageFullPath" />
      <svg class="svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <g class="circle">
          <circle class="time-elapsed-path" cx="50" cy="50" r="45" />
          <path
            class="time-left-path"
            v-if="timeLeft > 0"
            d="
            M 50, 50
            m -45, 0
            a 45,45 0 1,0 90,0
            a 45,45 0 1,0 -90,0
          "
            :style="{ strokeDasharray }"
          ></path>
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "CountDownTimer",
  props: {
    elapsedRef: {
      type: Number,
      required: true,
    },
    limitRef: {
      type: Number,
      required: true,
    },
    imageURL: {
      type: String,
      required: true,
    },
    baseURL: {
      type: String,
      required: false,
    },
  },

  computed: {
    // e.g. timeLeft of 100 -> '01:40'
    timeLeftString() {
      const timeLeft = this.timeLeft;
      const minutes =
        Math.floor(timeLeft / 60) > 0 ? Math.floor(timeLeft / 60) : 0;
      const seconds = timeLeft % 60 > 0 ? timeLeft % 60 : 0;
      return `${this.padToTwo(minutes)}:${this.padToTwo(seconds)}`;
    },
    timeLeft() {
      return this.limitRef - this.elapsedRef;
    },
    strokeDasharray() {
      const radius = 45;
      const total = 2 * Math.PI * radius;
      const timeFraction = this.timeLeft / this.limitRef;
      const adjTimeFraction = timeFraction - (1 - timeFraction) / this.limitRef;
      const elapsedDash = Math.floor(adjTimeFraction * total);
      return `${elapsedDash} ${total}`;
    },
    imageFullPath() {
      return `${this.imageURL}`;
    },
  },
  methods: {
    padToTwo(num) {
      //e.g 4->04
      return String(num).padStart(2, "0");
    },
  },
  
};
</script>

<style>
.cdt-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.cdt-container p {
  font-size: 12px;
}

.root {
  height: 10rem;
  width: 10rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.root img {
  position: absolute;
  width: 4rem;
}

.circle {
  fill: none;
  stroke: none;
}

.time-elapsed-path,
.time-left-path {
  stroke-width: 4px;
}

.time-left-path {
  stroke-width: 5px;
  transform: rotate(90deg);
  transform-origin: center;
  transition: 1s linear all;
  stroke: #f5f5f5;
}

.svg {
  transform: scaleX(1);
}
.timer-description{
  color: #282a3a;
  font-size: 0.8rem;
  font-weight: bold;
}
.timer-description-warning{
  color: #e30000;
  font-size: 0.8rem;
  font-weight: bold;
}
@media only screen and (min-device-width: 320px),
       only screen and (min-device-width: 390px),
       screen and (min-width: 1024px),
       screen and (min-device-width: 768px) and (max-device-width: 1024px),
       screen and (max-device-width: 480px) and (orientation: portrait),
       screen and (max-device-width: 640px) and (orientation: landscape),
       screen and (max-device-width: 640px) {
  .time-elapsed-path {
    stroke: #8b4494;
  }
}

@media only screen and (min-device-width: 320px) and (-webkit-min-device-pixel-ratio: 2),
       (device-height: 568px) and (device-width: 320px) and (-webkit-min-device-pixel-ratio: 2),
       (min-device-height: 667px) and (min-device-width: 375px) and (-webkit-min-device-pixel-ratio: 3) {
  .time-elapsed-path {
    stroke: #8b4494;
    /* stroke: #f5f5f5; */
  }
}
</style>
