<template>
  <div class="container">
    <div id="overlay" v-if="isLoading">
      <span class="loader"></span>
    </div>
    <div class="main" v-if="this.id && this.nonce">
      <div class="form form1" id="step1" v-if="currentStep === 1">
        <div class="header">
          <p class="header-amount-label">{{ $t("amount") }}</p>
          <p class="header-amount">
            {{ format2Decimals }} {{ uppcaseCurrency }}
          </p>
          <p class="header-merchant-label">{{ $t("merchant") }}</p>
          <p class="header-merchant-name">{{ nameMerchant }}</p>
          <p class="header-reference-label">{{ $t("orderID") }}</p>
          <p class="header-reference">{{ transactionRefrenceId }}</p>
        </div>

        <div class="med-body">
          <p class="payment-methods-title">{{ $t("paymentmethodTitle") }}</p>
          <div class="payment-methods-container">
            <div class="payment-methods-card" v-for="(met, index) in paymentMethods" :key="index">
              <div class="radio-toolbar " @click="selectOnlyThis(met, index)">
                <input type="radio" :id="index" name="radioFruit" :value="index" />
                <label >
                  <span class="tooltip-text">{{met.processor}}</span>
                  <picture v-if="met.processor == 'emis' && met.service == 'wallet'">
                    <source  srcset="../assets/express.webp" type="image/webp">
                    <img src="../assets/express.webp" :alt="`${met.processor}`"  class="grayscale">
                    <!-- <img :src="emis" :alt="`${met.processor}`" title="grayscale" class="grayscale" loading="lazy"> -->
                  </picture>
                  <picture v-if="met.processor == 'africell'">
                    <source srcset="../assets/afrimoney.webp" type="image/webp">
                    <img src="@/assets/afrimoney.webp" :alt="`${met.processor}`" class="grayscale">
                    <!-- <img v-if="met.processor == 'africell'" :src="afrimoney" :alt="`${met.processor}`" title="grayscale" class="grayscale" loading="lazy"> -->
                  </picture>
                  <picture v-if="met.processor == 'guita'">
                    <source srcset="../assets/guita.webp" type="image/webp">
                    <img src="@/assets/guita.webp" :alt="`${met.processor}`" class="grayscale">
                    <!-- <img v-if="met.processor == 'guita'" :src="guita" :alt="`${met.processor}`" title="grayscale" class="grayscale" /> -->
                  </picture>
                  <picture v-if="met.processor == 'paypay'">
                    <source srcset="../assets/paypay.webp" type="image/webp">
                    <img src="@/assets/paypay.webp" :alt="`${met.processor}`" class="grayscale">
                    <!-- <img v-if="met.processor == 'paypay'" :src="paypay" :alt="`${met.processor}`" title="grayscale" class="grayscale" /> -->
                  </picture>
                  <picture v-if="met.processor == 'emis' && met.service == 'reference'">
                    <source srcset="../assets/multicaixa.webp" type="image/webp">
                    <img src="@/assets/multicaixa.webp" :alt="`${met.processor}`" class="grayscale">
                    <!-- <img v-if="met.processor == 'emis' && met.service == 'reference'" :src="multicaixa" :alt="`${met.processor}`" title="grayscale" class="grayscale" /> -->
                  </picture>
                  <picture v-if="met.processor == 'ekwanza'">
                    <source srcset="../assets/ekwanza.webp" type="image/webp">
                    <img src="@/assets/ekwanza.webp" :alt="`${met.processor}`" class="grayscale">
                    <!-- <img v-if="met.processor == 'ekwanza'" :src="ekwanza" :alt="`${met.processor}`" title="grayscale" class="grayscale" /> -->
                  </picture>
                  <picture v-if="met.processor == 'unitel'">
                    <source srcset="../assets/unitel_money.webp" type="image/webp">
                    <img src="@/assets/unitel_money.webp" :alt="`${met.processor}`" class="grayscale">
                    <!-- <img v-if="met.processor == 'unitel'" :src="unitel" :alt="`${met.processor}`" title="grayscale" class="grayscale" /> -->
                  </picture>
                  <img v-if="met.processor == 'rawbank'" :src="rawbank" :alt="`${met.processor}`" title="grayscale" class="grayscale" />
                </label>
                <div class="custom-checkbox">
                  <img src="../assets/check.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer" @click="goForward()">
          <div class="btn ripple">{{ $t("next") }}</div>
        </div>
      </div>

      <div class="form form2" id="step2" v-if="currentStep === 2">
        <div class="header">
          <p class="header-amount-label">{{ $t("amount") }}</p>
          <p class="header-amount">
            {{ format2Decimals }} {{ uppcaseCurrency }}
          </p>
          <p class="header-merchant-label">{{ $t("merchant") }}</p>
          <p class="header-merchant-name">{{ nameMerchant }}</p>
          <p class="header-reference-label">{{ $t("orderID") }}</p>
          <p class="header-reference">{{ transactionRefrenceId }}</p>
        </div>
        <div class="med-body number">
          <div class="input-container">
            <img :src="`${this.imageURL}`" alt="" />

            <div class="input-card" v-if="!authloader">
              <div class="input-container">
                <span class="input-text" v-if="country == 'ao'">🇦🇴 +244</span>
                <span class="input-text" v-if="country == 'cd'">🇨🇩 +243</span>
                <input class="input-field" type="tel" maxlength="9" v-model="cellphone" @input="formatPhoneNumber" placeholder="xxx xxx xxx" inputmode="numeric">
              </div>
              <p align="justify" class="instrution" v-if="this.proccessorMethod == 'emis' && this.serviceMethod == 'wallet'">
                {{ $t("paymentInstrutionPart1") }}
                <span>Multicaixa express</span>
                {{ $t("paymentInstrutionPart2") }}
              </p>
              <p align="justify" class="instrution" v-if="this.proccessorMethod == 'emis' && this.serviceMethod == 'reference'">
                {{ $t("referenceIntrution") }}
              </p>
              <p class="instrution" v-if="this.proccessorMethod == 'unitel'">
                {{ $t("paymentInstrutionPart1") }} <span> Unitel money</span>
                {{ $t("paymentInstrutionPart2") }}
              </p>
              <p class="instrution" v-if="this.proccessorMethod == 'guita'">
                {{ $t("paymentInstrutionPart1") }} <span> Guita</span>
                {{ $t("paymentInstrutionPart2") }}
              </p>
              <p class="instrution" v-if="this.proccessorMethod == 'rawbank'">
                {{ $t("paymentInstrutionPart1") }} <strong> Illicocash</strong>
                {{ $t("paymentInstrutionPart2") }}
              </p>
            </div>

            <span v-else class="loader-btn"></span>
          </div>
        </div>
        <div class="footer2">
          <div class="btn btn-backward ripple" @click="goBackward">
            {{ $t("back") }}
          </div>
          <div class="btn btn-forward ripple" @click="authorization">
            {{ $t("next") }}
          </div>
        </div>
      </div>

      <div class="form form3" id="step3" v-if="currentStep === 3">
        <div class="header">
          <p class="header-amount-label">{{ $t("amount") }}</p>
          <p class="header-amount">
            {{ format2Decimals }} {{ uppcaseCurrency }}
          </p>
          <p class="header-merchant-label">{{ $t("merchant") }}</p>
          <p class="header-merchant-name">{{ nameMerchant }}</p>
          <p class="header-reference-label">{{ $t("orderID") }}</p>
          <p class="header-reference">{{ transactionRefrenceId }}</p>
        </div>
        <div class="med-body opt-container">
          <img :src="`${this.imageURL}`" alt="" />
          <OtpTimer id="timer" :elapsed1="timeElapsed1" :limit1="timeLimit1" />
          <div class="opt-content" v-if="!otpSent">
            <VOtpInput v-model="otp" :value="otp" ref="otpInput" input-classes="otp-input" :num-inputs="6"
              :should-auto-focus="true" separator=" " :is-input-num="true"
              :conditionalClass="['one', 'two', 'three', 'four', 'fifh', 'six']"
              :placeholder="['*', '*', '*', '*', '*', '*']" />
          </div>
          <span v-else class="loader-btn"></span>
        </div>
        <div class="footer3" @click="authorization">
          <div class="btn btn-backward ripple" @click="goBackward">
            {{ $t("back") }}
          </div>
          <div class="btn btn-forward ripple" @click="sendOtp">
            {{ $t("submit") }}
          </div>
        </div>
      </div>

      <div class="form form4" id="step4" v-if="currentStep === 4">
        <div class="header">
          <p class="header-amount-label">{{ $t("amount") }}</p>
          <p class="header-amount">
            {{ format2Decimals }} {{ uppcaseCurrency }}
          </p>
          <p class="header-merchant-label">{{ $t("merchant") }}</p>
          <p class="header-merchant-name">{{ nameMerchant }}</p>
          <p class="header-reference-label">{{ $t("orderID") }}</p>
          <p class="header-reference">{{ transactionRefrenceId }}</p>
          <div v-if="this.proccessorMethod == 'ekwanza'">
            <p v-if="this.proccessorMethod == 'ekwanza' && formattedEkwanzaReference != 'null'" class="header-reference-label">{{ $t("reference") }}</p>
            <!-- <p v-if="this.proccessorMethod == 'ekwanza' && formattedEkwanzaReference != 'null'" class="header-reference" style="font-size: 1.3rem;">{{ formattedEkwanzaReference }}</p> -->
            <InputCopyClipBoard v-model="formattedEkwanzaReference" :phoneNumber="formattedEkwanzaReference"/>
          </div>
        </div>
        <div class="med-body countdown-container">
          <p class="warning">
            {{ $t("warning") }}
          </p>
          <CountDownTimer :baseURL="baseURL" :imageURL="imageURL" :elapsed="timeElapsed" :limit="timeLimit" />
        </div>
        <div class="footer-countdown">
          <p class="description" v-if="this.timeLimit != this.timeElapsed">{{ $t("waiting") }}</p>
        </div>
      </div>

      <div class="complete" v-if="currentStep === 5">
        <div class="header">
          <p class="header-amount-label">{{ $t("amount") }}</p>
          <p class="header-amount">
            {{ format2Decimals }} {{ uppcaseCurrency }}
          </p>
          <p class="header-merchant-label">{{ $t("merchant") }}</p>
          <p class="header-merchant-name">{{ nameMerchant }}</p>
          <p class="header-reference-label">{{ $t("orderID") }}</p>
          <p class="header-reference">{{ transactionRefrenceId }}</p>
          <div v-if="this.proccessorMethod == 'ekwanza'">
            <p v-if="this.proccessorMethod == 'ekwanza' && formattedEkwanzaReference != 'null'" class="header-reference-label">{{ $t("reference") }}</p>
            <InputCopyClipBoard v-model="formattedEkwanzaReference" :phoneNumber="formattedEkwanzaReference"/>
          </div>
          <!-- <p v-if="this.proccessorMethod == 'ekwanza' && formattedEkwanzaReference != 'null'" class="header-reference-label">{{ $t("reference") }}</p>
          <p v-if="this.proccessorMethod == 'ekwanza' && formattedEkwanzaReference != 'null'" class="header-reference" style="font-size: 1.3rem;">{{ formattedEkwanzaReference }}</p> -->
        </div>
        <div class="med-body complete-container" v-if="this.transactionStatus === 'accepted'">
          <img src="../assets/complete.svg" alt="complete" />
          <p>{{ $t("message.2000") }}</p>
        </div>
        <div class="rejected-container" v-if="this.transactionStatus === 'rejected'">
          <img src="../assets/error.svg" alt="error" />
          <p v-if="statusCode == 3000">{{ $t("message.3000") }}</p>
          <p v-if="statusCode == 3001">{{ $t("message.3001") }}</p>
          <p v-if="statusCode == 3002">{{ $t("message.3002") }}</p>
          <p v-if="statusCode == 3003">{{ $t("message.3003") }}</p>
          <p v-if="statusCode == 3004">{{ $t("message.3004") }}</p>
          <p v-if="statusCode == 3005">{{ $t("message.3005") }}</p>
          <p v-if="statusCode == 3006">{{ $t("message.3006") }}</p>
          <p v-if="statusCode == 4000">{{ $t("message.4000") }}</p>
          <p v-if="statusCode == 4003">{{ $t("message.4003") }}</p>
          <p v-if="statusCode == 4004">{{ $t("message.4004") }}</p>
          <p v-if="statusCode == 9000">{{ $t("message.9000") }}</p>
          <p v-if="statusCode == null">{{ $t("unknown") }}</p>
          <p v-if="statusCode == ''">{{ $t("unknown") }}</p>

          <div class="footerComplete" v-if="statusCode == 3000 ||
            statusCode == 3001 ||
            statusCode == 3002 ||
            statusCode == 3003 ||
            statusCode == 3004 ||
            statusCode == 3005 ||
            statusCode == 3006 ||
            statusCode == 4000 ||
            statusCode == 4003 ||
            statusCode == 4004 ||
            statusCode == 9000 ||
            statusCode == null ||
            statusCode == ''
            ">
            <div class="btn btn-retry ripple" @click="handleRetry">
              {{ $t("retry") }}
            </div>
            <div class="btn btn-cancel ripple" @click="handleOpenCancelModal">
              {{ $t("cancel") }}
            </div>
          </div>
          <div class="modal-cancel-container" v-if="showModalCancel">
            <div class="modal-cancel">
              <p class="modal-cancel-title">
                Are you sure you want to cancel ?
              </p>
              <div class="modal-controls">
                <div class="btn btn-yes ripple" @click="handleFailureRedirect">
                  {{ $t("yes") }}
                </div>
                <div class="btn btn-no ripple" @click="handleCloseCancelModal">
                  {{ $t("no") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="not-found" v-if="currentStep === 6">
        <img src="../assets/notfound.jpg" alt="notfound" />
        <p>{{ $t("transactionNotFound") }}</p>
      </div>
      <div class="form form4" id="step4" v-if="currentStep === 7">
        <div class="header">
          <p class="header-merchant-label">{{ $t("merchant") }}</p>
          <p class="header-merchant-name">{{ nameMerchant }}</p>
          <div class="header-content">
            <div class="table-container">
              <table>
                <tbody>
                  <tr>
                    <th>{{ $t("entity") }}</th>
                    <td>{{ entity }}</td>
                  </tr>
                  <tr>
                    <th>{{ $t("reference") }}</th>
                    <td>{{ formattedReference }}</td>
                  </tr>
                  <tr>
                    <th>{{ $t("amount") }}</th>
                    <td>{{ format2Decimals }} {{ uppcaseCurrency }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <p class="header-order-id-label">{{ $t("orderID") }}</p>
          <p class="header-entity">{{  transactionRefrenceId }}</p>
          
        </div>

        <p class="header-order-intrution">{{ $t("referenceIntrution") }}</p>
        <div class="med-body countdown-container">
          <p class="warning">
            {{ $t("warning") }}
          </p>
          <CountDownTimerRef :baseURL="baseURL" :imageURL="imageURL" :elapsedRef="timeElapsedRef" :limitRef="timeLimitRef" />
        </div>
        <div class="footer-countdown">
          <p class="description" v-if="this.timeLimitRef != this.timeElapsedRef">{{ $t("waiting") }}</p>
        </div>
      </div>
      <div class="form form-redirect" v-if="currentStep === 8">
        <p class="counter-title">
          {{ $t("redirectMessage") }}
        </p>
        <p class="counter">{{ counter }}</p>
      </div>
      <div class="form internal-error" v-if="currentStep === 9">
        <img src="../assets/notfound.jpg" alt="notfound" />
        <p>Contact the support</p>
      </div>
    </div>
    <div class=" form empty" v-else>
      <div class="empty-card">
        <img src="../assets/fail.png" />
        <p>Oops! Something went wrong</p>
        <p>Please try again later</p>
      </div>
    </div>
  </div>
</template>
<script>
import CountDownTimer from "../components/CountDownTimer.vue";
import CountDownTimerRef from "../components/CountDownTimerRef.vue";
import OtpTimer from "../components/OtpTimer.vue";
import VOtpInput from "vue3-otp-input";
import { mask } from "vue-the-mask";
import { useToast } from "vue-toastification";
import emis from '@/assets/express.svg';
import afrimoney from '@/assets/afrimoney.svg';
import ekwanza from '@/assets/ekwanza.svg';
import guita from '@/assets/guita.svg';
import multicaixa from '@/assets/multicaixa.svg';
import paypay from '@/assets/paypay.svg';
import unitel from '@/assets/unitel_money.svg';
import rawbank from '@/assets/illicocash.png';
import InputCopyClipBoard from "../components/InputCopyClipBoard.vue";

export default {
  name: "App",
  directives: { mask },
  components: {
    OtpTimer,
    VOtpInput,
    CountDownTimer,
    CountDownTimerRef,
    InputCopyClipBoard
  },
  data() {
    return {
      payment: [],
      currentStep: 1,
      timeElapsed: 0,
      timerInterval: undefined,
      timeLimit: 180,
      timeElapsed1: 0,
      timerInterval1: undefined,
      timeLimit1: 90,
      isChecked: false,
      transactionAmount: "",
      transactionRefrenceId: "",
      transactionCurrency: "",
      nameMerchant: "",
      paymentMethods: [],
      transactionId: "",
      cellphone: "",
      customerCell: "",
      serviceMethod: "",
      proccessorMethod: "",
      locationAuthorization: "",
      locationConfirmation: "",
      parentID: "",
      otpCode: "",
      otp: "",
      otpSent: false,
      authloader: false,
      selectMet: false,
      finalData: [],
      id: "",
      nonce: "",
      transactionStatus: "",
      isLoading: true,
      isOuttime: true,
      codefouroufour: "",
      lctAuthorization: "",
      AuthArr: [],
      statusCode: "",
      confirmationID: "",
      location: "",
      imageURL: "",
      resArr: [],
      showModalCancel: false,
      counter: 3,
      urlObj: [],
      urlFailure: "",
      urlSuccess: "",
      response: {},
      country: "",
      entity: "",
      ref: "",
      reference: "",
      timeElapsedRef: 0,
      timeLimitRef: 600,
      baseURLs: process.env.VUE_APP_BASE_URL,
      emis: emis,
      unitel: unitel,
      afrimoney: afrimoney,
      ekwanza: ekwanza,
      guita: guita,
      multicaixa: multicaixa,
      paypay: paypay,
      rawbank: rawbank,
    };
  },
  computed: {
    format2Decimals() {
      const format = parseFloat(this.transactionAmount).toFixed(2);
      return format.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    formattedReference() {
      return String(this.ref).replace(/(\d{3})/g, '$1 ');
    },
    formattedEkwanzaReference() {
      return String(this.ref).replace(/(\d{4})/g, '$1 ');
    },
    formatReference() {
      return String(this.reference);
    },
    uppcaseCurrency() {
      return this.transactionCurrency.toUpperCase();
    },
    baseURL() {
      return process.env.VUE_APP_BASE_URL;
    },
  },
  watch: {
    country: 'formatPhoneNumber',
    cellphone: 'formatPhoneNumber'
  },
  methods: {
    handleOpenCancelModal() {
      this.showModalCancel = true;
    },
    async handleFailureRedirect() {
      //CONFIRM COMPLETE
      const response = await fetch(
        `${this.baseURL}/v1/hosts/jungleworks/${this.transactionId}/complete`,
        {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error(`Error with confirmation${response.status}`);
      }

      const finalDATA = await response.json();
      const {
        url: { failure },
      } = finalDATA;
      this.urlFailure = failure;
      // this.urlFailure =
      //   "https://live-payfort.yelo.red/payment_gateways/error.html?paymentMethod=600000000000331&domain_name=http://store-staging.tupuca.com&reference_id=3659463";

      this.showModalCancel = false;
      /* eslint-disable */
      const isValidUrl = (urlString) => {
        var urlPattern = new RegExp(
          /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{2,63}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/i
        ); // eslint-disable-line
        return !!urlPattern.test(urlString);
      };

      if (isValidUrl(this.urlFailure)) {
        this.currentStep = 8;
        this.countDownFailure();
      } else {
        useToast().error("INVALID CANCEL URL");
      }
    },
    formatPhoneNumber() {
      // Remove non-numeric characters
      this.cellphone = this.cellphone.replace(/\D/g, "");

      // Verifica se o Country e ao
      if (this.country === 'ao' && this.cellphone.length >= 1) {
        // Ensure the phone number starts with '9'
        if (this.cellphone.charAt(0) !== '9') {
          this.cellphone = '9' + this.cellphone.slice(1);
        }

        // Insert spaces every three characters
        this.cellphone = this.cellphone.replace(/(\d{3})(\d{3})(\d{3})/, "$1 $2 $3");
      } else if (this.country === 'cd' && this.cellphone.length >= 1) {
        // Ensure the phone number starts with '8' or '9'
        if (this.cellphone.charAt(0) !== '8' && this.cellphone.charAt(0) !== '9') {
          this.cellphone = '8' + this.cellphone.slice(1);
        }

        // Insert spaces every three characters
        this.cellphone = this.cellphone.replace(/(\d{3})(\d{3})(\d{3})/, "$1 $2 $3");
      } else {
        // Handle other countries or invalid scenarios if needed
        if (this.cellphone.length >= 1) {
          // Insert spaces every three characters
          this.cellphone = this.cellphone.replace(/(\d{3})(\d{3})(\d{3})/, "$1 $2 $3");
        }
      }
    },
    handleSuccessRedirect() {
      // this.showModalCancel = false;
      this.currentStep = 8;
      this.countDownSuccess();
    },
    countDownFailure() {
      //If the counter has not reached the end
      if (this.counter > 0) {
        //Wait 1 second, then decrement the counter
        setTimeout(() => {
          this.counter--;
          this.countDownFailure();
        }, 1000);
      } else {
        //Count down has reached zero, redirect
        window.location = `${this.urlFailure}`;
      }
    },
    countDownSuccess() {
      //If the counter has not reached the end
      if (this.counter > 0) {
        //Wait 1 second, then decrement the counter
        setTimeout(() => {
          this.counter--;
          this.countDownSuccess();
        }, 1000);
      } else {
        //Count down has reached zero, redirect
        window.location = `${this.urlSuccess}`;
      }
    },
    handleCloseCancelModal() {
      this.showModalCancel = false;
    },
    handleRetry() {
      this.cellphone = "";
      this.authloader = false;
      this.stopTimer();
      this.currentStep = 1;
    },
    selectOnlyThis(item) {
      if (!item) {
        alert("select method");
      }
      let image_url = '';
      const { service, processor, entity } = item;

      if (processor === 'emis' && service === 'wallet') {
        image_url = this.emis;
      } else if (processor === 'africell') {
        image_url = this.afrimoney;
        this.timeLimit = 300;
      } else if (processor === 'guita') {
        image_url = this.guita;
      } else if (processor === 'paypay') {
        image_url = this.paypay;
      } else if (processor === 'emis' && service === 'reference') {
        image_url = this.multicaixa;
      } else if (processor === 'ekwanza') {
        image_url = this.ekwanza;
        this.timeLimit = 300;
      } else if (processor === 'unitel') {
        image_url = this.unitel;
      } else if (processor === 'rawbank') {
        image_url = this.rawbank;
      }

      this.serviceMethod = service;
      this.proccessorMethod = processor;
      this.imageURL = image_url;
      this.entity = entity;
    },
    goForward() {
      if (!this.proccessorMethod) {
        useToast().error(this.$t("paymentMethodAlert"));
      } else {
        this.currentStep += 1;
      }
    },
    goBackward() {
      this.currentStep -= 1;
      this.authloader = false;
      this.proccessorMethod = "";
    },

    //POST AUTHORIZATION

    async authorization() {
      if (!this.cellphone && this.cellphone.length <= 9) {
        useToast().error(this.$t("cellphoneAlert"));

      } else {
        this.authloader = true;
        this.startTimer1();
      
        switch (this.proccessorMethod) {
          case "guita": {
            if (!("headers" in this.response)) {
              this.response = await fetch(
                `${this.baseURL}/v1/hosts/transactions`,
                {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    id: this.transactionId,
                    type: "authorization",
                    service: this.serviceMethod,
                    processor: this.proccessorMethod,
                    customer: `${this.cellphone.replace(/\s+/g, "")}`,
                  }),
                }
              );

              if (!this.response.ok) {
                if (this.response.status === 400) {
                  useToast().error("BAD REQUEST");
                  this.authloader = false;
                }
                throw new Error(`HTTP error! status: ${this.response.status}`);
              }
              this.cellphoneValue = "";
              //  const data = await response.json();
              const location = await this.response.headers.get("Location");

              this.locationAuthorization = location;


              let intervalID = setInterval(async () => {
                const res1 = await fetch(
                  `${this.baseURL}${this.locationAuthorization}`,
                  {
                    method: "GET",
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                    },
                  }
                );

                if (!res1.ok) {
                  throw new Error(`HTTP error! status: ${res1.status}`);
                }

                const result = await res1.json();
                this.AuthArr = result;

                const { status, status_code, id } = this.AuthArr;
                this.parentID = id;

                if (status === "rejected") {
                  this.$cookies.set("status", "rejected", "transaction status");
                  this.statusCode = status_code;
                  this.transactionStatus = status;
                  this.currentStep = 5;
                  this.authloader = false;
                  clearInterval(intervalID);
                }

                if (status === "accepted") {
                  this.$cookies.set("status", "accepted", "transaction status");
                  this.currentStep += 1;
                  this.authloader = false;
                  clearInterval(intervalID);
                }
              }, 2000);
            }
            break;
          }
          case "emis": {
            if (this.serviceMethod == "wallet") {
              const response = await fetch(
                `${this.baseURL}/v1/hosts/transactions`,
                {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    id: this.transactionId,
                    type: "payment",
                    service: this.serviceMethod,
                    processor: this.proccessorMethod,
                    customer: `${this.cellphone.replace(/\s+/g, "")}`,
                  }),
                }
              );
              if (!response.ok) {
                useToast().error(`${response.message}`);
                throw new Error(`HTTP error! status: ${response.status}`);
              }
              // const data = await response.json();
              const location = await response.headers.get("Location");
              this.location = location;

              const res = await fetch(`${this.baseURL}${this.location}`, {
                method: "GET",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              });

              if (!response.ok) {
                throw new Error(`HTTP error! status: ${res.status}`);
              }

              const result = await res.json();

              // const { status, status_code } = result;

              this.currentStep = 4;
              this.startTimer();
              //Monitorar estado da transaction

              let interval = setInterval(async () => {
                const res1 = await fetch(`${this.baseURL}${this.location}`, {
                  method: "GET",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                });

                if (!response.ok) {
                  throw new Error(`HTTP error! status: ${res1.status}`);
                }

                const arr = await res1.json();

                const { status, status_code } = arr;

                if (status == "accepted") {
                  this.$cookies.set("status", "accepted", "transaction status");
                  // this.transactionStatus =status;
                  this.statusCode = status_code;

                  clearInterval(interval);

                  //CONFIRM COMPLETE
                  const resp = await fetch(
                    `${this.baseURL}/v1/hosts/jungleworks/${this.transactionId}/complete`,
                    {
                      method: "PATCH",
                      headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                      },
                    }
                  );
                  if (!response.ok) {
                    throw new Error(`Error with confirmation${response.status}`);
                  }

                  const finalResult = await resp.json();
                  // this.finalData = finalResult;

                  const { status } = finalResult;
                  const {
                    url: { success },
                  } = finalResult;

                  this.urlSuccess = success;
                  this.transactionStatus = status;

                  this.currentStep += 1;
                  this.authloader = false;

                  const isValidUrl = (urlString) => {
                    /* eslint-disable */
                    var urlPattern = new RegExp(
                      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{2,63}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/i
                    ); // eslint-disable-line
                    return !!urlPattern.test(urlString);
                  };

                  if (isValidUrl(this.urlSuccess)) {
                    setTimeout(() => {
                      this.handleSuccessRedirect();
                    }, 3000);
                  }
                }

                if (status == "rejected") {
                  this.$cookies.set("status", "rejected", "transaction status");
                  this.statusCode = status_code;
                  this.transactionStatus = status;
                  this.currentStep += 1;
                  clearInterval(interval);
                }
                if (this.timeLimit <= this.timeElapsed){
                  this.$cookies.set("status", "expired", "transaction status");
                  clearInterval(interval);
                }
              }, 5000);
            } else {
              const response = await fetch(
                `${this.baseURL}/v1/hosts/transactions`,
                {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    id: this.transactionId,
                    type: "payment",
                    service: this.serviceMethod,
                    processor: this.proccessorMethod,
                    customer: `${this.cellphone.replace(/\s+/g, "")}`,
                  }),
                }
              );
              if (!response.ok) {
                useToast().error(`${response.message}`);
                throw new Error(`HTTP error! status: ${response.status}`);
              }
              // const data = await response.json();
              const location = await response.headers.get("Location");

              this.location = location;
             
              const res = await fetch(`${this.baseURL}${this.location}`, {
                method: "GET",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              });

              if (!response.ok) {
                throw new Error(`HTTP error! status: ${res.status}`);
              }

              const result = await res.json();
              const { reference } = result;
              this.ref = reference;
              this.currentStep = 7;
              this.startTimerRef();
              

              //Monitorar estado da transaction

              let interval = setInterval(async () => {
                const res1 = await fetch(`${this.baseURL}${this.location}`, {
                  method: "GET",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                });

                if (!response.ok) {
                  throw new Error(`HTTP error! status: ${res1.status}`);
                }

                const arr = await res1.json();
                const { status, status_code } = arr;
               
                if (status == "accepted") {
                  this.$cookies.set("status", "accepted", "transaction status");
                  // this.transactionStatus =status;
                  this.statusCode = status_code;

                  clearInterval(interval);

                  //CONFIRM COMPLETE
                  const resp = await fetch(
                    `${this.baseURL}/v1/hosts/jungleworks/${this.transactionId}/complete`,
                    {
                      method: "PATCH",
                      headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                      },
                    }
                  );
                  if (!response.ok) {
                    throw new Error(`Error with confirmation${response.status}`);
                  }

                  const finalResult = await resp.json();
                  // this.finalData = finalResult;

                  const { status } = finalResult;
                  const {
                    url: { success },
                  } = finalResult;

                  this.urlSuccess = success;
                  this.transactionStatus = status;

                  this.currentStep = 4
                  this.authloader = false;

                  const isValidUrl = (urlString) => {
                    /* eslint-disable */
                    var urlPattern = new RegExp(
                      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{2,63}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/i
                    ); // eslint-disable-line
                    return !!urlPattern.test(urlString);
                  };

                  if (isValidUrl(this.urlSuccess)) {
                    setTimeout(() => {
                      this.handleSuccessRedirect();
                    }, 3000);
                  }
                }

                if (status == "rejected") {
                  this.$cookies.set("status", "rejected", "transaction status");
                  this.statusCode = status_code;
                  this.transactionStatus = status;
                  this.currentStep = 4;
                  clearInterval(interval);
                }
                
                if (this.timeLimitRef <= this.timeElapsedRef){
                  this.$cookies.set("status", "expired", "transaction status");
                  clearInterval(interval);
                }
              }, 5000);

            }

            break;
          }
          case "unitel": {
            const response = await fetch(
              `${this.baseURL}/v1/hosts/transactions`,
              {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  id: this.transactionId,
                  type: "payment",
                  service: this.serviceMethod,
                  processor: this.proccessorMethod,
                  customer: `${this.cellphone.replace(/\s+/g, "")}`,
                }),
              }
            );
            if (!response.ok) {
              useToast().error(`${response.message}`);
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            // const data = await response.json();
            const location = await response.headers.get("Location");

            this.location = location;

            const res = await fetch(`${this.baseURL}${this.location}`, {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            });

            if (!response.ok) {
              throw new Error(`HTTP error! status: ${res.status}`);
            }

            const result = await res.json();
            // const { status, status_code } = result;

            this.currentStep = 4;
            this.startTimer();
            //Monitorar estado da transaction

            let interval = setInterval(async () => {
              const res1 = await fetch(`${this.baseURL}${this.location}`, {
                method: "GET",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              });

              if (!response.ok) {
                throw new Error(`HTTP error! status: ${res1.status}`);
              }

              const arr = await res1.json();

              const { status, status_code } = arr;

              if (status == "accepted") {
                this.$cookies.set("status", "accepted", "transaction status");
                // this.transactionStatus =status;
                this.statusCode = status_code;

                clearInterval(interval);

                //CONFIRM COMPLETE
                const resp = await fetch(
                  `${this.baseURL}/v1/hosts/jungleworks/${this.transactionId}/complete`,
                  {
                    method: "PATCH",
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                    },
                  }
                );
                if (!response.ok) {
                  throw new Error(`Error with confirmation${response.status}`);
                }

                const finalResult = await resp.json();
                // this.finalData = finalResult;

                const { status } = finalResult;
                const {
                  url: { success },
                } = finalResult;

                this.urlSuccess = success;
                this.transactionStatus = status;

                // if (status == "rejected") {
                //   this.currentStep += 1;
                //   this.authloader = false;
                // } else {
                this.currentStep += 1;
                this.authloader = false;

                const isValidUrl = (urlString) => {
                  /* eslint-disable */
                  var urlPattern = new RegExp(
                    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{2,63}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/i
                  ); // eslint-disable-line
                  return !!urlPattern.test(urlString);
                };

                if (isValidUrl(this.urlSuccess)) {
                  setTimeout(() => {
                    this.handleSuccessRedirect();
                  }, 3000);
                }
              }
              if (status == "rejected") {
                this.$cookies.set("status", "rejected", "transaction status");
                this.statusCode = status_code;

                this.transactionStatus = status;
                this.currentStep += 1;
                clearInterval(interval);
              }
              if (this.timeLimit <= this.timeElapsed){
                this.$cookies.set("status", "expired", "transaction status");
                clearInterval(interval);
              }
            }, 5000);
            break;
          }
          case "africell": {
            const response = await fetch(
              `${this.baseURL}/v1/hosts/transactions`,
              {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  id: this.transactionId,
                  type: "payment",
                  service: this.serviceMethod,
                  processor: this.proccessorMethod,
                  customer: `${this.cellphone.replace(/\s+/g, "")}`,
                }),
              }
            );
            if (!response.ok) {
              useToast().error(`${response.message}`);
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            // const data = await response.json();
            const location = await response.headers.get("Location");

            this.location = location;

            const res = await fetch(`${this.baseURL}${this.location}`, {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            });

            if (!response.ok) {
              throw new Error(`HTTP error! status: ${res.status}`);
            }

            const result = await res.json();
            // const { status, status_code } = result;

            this.currentStep = 4;
            this.startTimer();
            //Monitorar estado da transaction

            let interval = setInterval(async () => {
              const res1 = await fetch(`${this.baseURL}/${this.location}`, {
                method: "GET",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              });

              if (!response.ok) {
                throw new Error(`HTTP error! status: ${res1.status}`);
              }

              const arr = await res1.json();

              const { status, status_code } = arr;

              if (status == "accepted") {
                this.$cookies.set("status", "accepted", "transaction status");
                // this.transactionStatus =status;
                this.statusCode = status_code;

                clearInterval(interval);

                //CONFIRM COMPLETE
                const resp = await fetch(
                  `${this.baseURL}/v1/hosts/jungleworks/${this.transactionId}/complete`,
                  {
                    method: "PATCH",
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                    },
                  }
                );
                if (!response.ok) {
                  throw new Error(`Error with confirmation${response.status}`);
                }

                const finalResult = await resp.json();
                // this.finalData = finalResult;

                const { status } = finalResult;
                const {
                  url: { success },
                } = finalResult;

                this.urlSuccess = success;

                this.transactionStatus = status;

                // if (status == "rejected") {
                //   this.currentStep += 1;
                //   this.authloader = false;
                // } else {
                this.currentStep += 1;
                this.authloader = false;

                const isValidUrl = (urlString) => {
                  /* eslint-disable */
                  var urlPattern = new RegExp(
                    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{2,63}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/i
                  ); // eslint-disable-line
                  return !!urlPattern.test(urlString);
                };

                if (isValidUrl(this.urlSuccess)) {
                  setTimeout(() => {
                    this.handleSuccessRedirect();
                  }, 3000);
                }
              }
              if (status == "rejected") {
                this.$cookies.set("status", "rejected", "transaction status");
                this.statusCode = status_code;

                this.transactionStatus = status;
                this.currentStep += 1;
                clearInterval(interval);
              }

              if (this.timeLimit <= this.timeElapsed){
                this.$cookies.set("status", "expired", "transaction status");
                clearInterval(interval);
              }
            }, 5000);
            break;
          }
          case "paypay": {
            const response = await fetch(
              `${this.baseURL}/v1/hosts/transactions`,
              {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  id: this.transactionId,
                  type: "payment",
                  service: this.serviceMethod,
                  processor: this.proccessorMethod,
                  customer: `${this.cellphone.replace(/\s+/g, "")}`,
                }),
              }
            );
            if (!response.ok) {
              useToast().error(`${response.message}`);
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            // const data = await response.json();
            const location = await response.headers.get("Location");


            this.location = location;

            const res = await fetch(`${this.baseURL}${this.location}`, {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            });

            if (!response.ok) {
              throw new Error(`HTTP error! status: ${res.status}`);
            }

            const result = await res.json();
            // const { status, status_code } = result;

            this.currentStep = 4;
            this.startTimer();
            //Monitorar estado da transaction

            let interval = setInterval(async () => {
              const res1 = await fetch(`${this.baseURL}${this.location}`, {
                method: "GET",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              });

              if (!response.ok) {
                throw new Error(`HTTP error! status: ${res1.status}`);
              }

              const arr = await res1.json();

              const { status, status_code } = arr;

              if (status == "accepted") {
                this.$cookies.set("status", "accepted", "transaction status");
                // this.transactionStatus =status;
                this.statusCode = status_code;

                clearInterval(interval);

                //CONFIRM COMPLETE
                const resp = await fetch(
                  `${this.baseURL}/v1/hosts/jungleworks/${this.transactionId}/complete`,
                  {
                    method: "PATCH",
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                    },
                  }
                );
                if (!response.ok) {
                  throw new Error(`Error with confirmation${response.status}`);
                }

                const finalResult = await resp.json();
                // this.finalData = finalResult;

                const { status } = finalResult;
                const {
                  url: { success },
                } = finalResult;

                this.urlSuccess = success;


                this.transactionStatus = status;

                // if (status == "rejected") {
                //   this.currentStep += 1;
                //   this.authloader = false;
                // } else {
                this.currentStep += 1;
                this.authloader = false;

                const isValidUrl = (urlString) => {
                  /* eslint-disable */
                  var urlPattern = new RegExp(
                    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{2,63}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/i
                  ); // eslint-disable-line
                  return !!urlPattern.test(urlString);
                };

                if (isValidUrl(this.urlSuccess)) {
                  setTimeout(() => {
                    this.handleSuccessRedirect();
                  }, 3000);
                }
              }
              if (status == "rejected") {
                this.$cookies.set("status", "rejected", "transaction status");
                this.statusCode = status_code;

                this.transactionStatus = status;
                this.currentStep += 1;
                clearInterval(interval);
              }
              if (this.timeLimit <= this.timeElapsed){
                this.$cookies.set("status", "expired", "transaction status");
                clearInterval(interval);
              }
            }, 5000);
            break;
          }
          case "rawbank": {
            if (!("headers" in this.response)) {
              this.response = await fetch(
                `${this.baseURL}/v1/hosts/transactions`,
                {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    id: this.transactionId,
                    type: "authorization",
                    service: this.serviceMethod,
                    processor: this.proccessorMethod,
                    customer: `${this.cellphone.replace(/\s+/g, "")}`,
                  }),
                }
              );

              if (!this.response.ok) {
                if (this.response.status === 400) {
                  useToast().error("BAD REQUEST");
                  this.authloader = false;
                }
                throw new Error(`HTTP error! status: ${this.response.status}`);
              }
              this.cellphoneValue = "";
              //  const data = await response.json();
              const location = await this.response.headers.get("Location");

              this.locationAuthorization = location;

              let intervalID = setInterval(async () => {
                const res1 = await fetch(
                  `${this.baseURL}${this.locationAuthorization}`,
                  {
                    method: "GET",
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                    },
                  }
                );

                if (!res1.ok) {
                  throw new Error(`HTTP error! status: ${res1.status}`);
                }

                const result = await res1.json();

                this.AuthArr = result;

                const { status, status_code, id } = this.AuthArr;
                this.parentID = id;

                if (status === "rejected") {
                  this.$cookies.set("status", "rejected", "transaction status");
                  this.statusCode = status_code;
                  this.transactionStatus = status;
                  this.currentStep = 5;
                  this.authloader = false;
                  clearInterval(intervalID);
                }

                if (status === "accepted") {
                  this.$cookies.set("status", "accepted", "transaction status");
                  this.currentStep += 1;

                  this.authloader = false;
                  clearInterval(intervalID);
                }
              }, 2000);
            }
            break;
          }
          case "ekwanza": {
            
            const response = await fetch(
              `${this.baseURL}/v1/hosts/transactions`,
              {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  id: this.transactionId,
                  type: "payment",
                  service: this.serviceMethod,
                  processor: this.proccessorMethod,
                  customer: `${this.cellphone.replace(/\s+/g, "")}`, 
                }),
              }
            );
            if (!response.ok) {
              useToast().error(`${response.message}`);
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            // const data = await response.json();
            const location = await response.headers.get("Location");


            this.location = location;

            const res = await fetch(`${this.baseURL}${this.location}`, {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            });

            if (!res.ok) {
              throw new Error(`HTTP error! status: ${res.status}`);
            }

            const result = await res.json();
            const { reference } = result;
            this.ref = reference;
            this.currentStep = 4;
            this.startTimer();

            //Monitorar estado da transaction
            let interval = setInterval(async () => {
              try {
                // Fetching data from the API
                const res1 = await fetch(`${this.baseURL}${this.location}`, {
                  method: "GET",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                });

                if (!res1.ok) {
                  throw new Error(`HTTP error! status: ${res1.status}`);
                }

                const arr = await res1.json();
                const { status, status_code, reference } = arr;
                this.ref = reference;

                // Check the status of the response
                if (status.replace(/\s/g, "") === "accepted") {
                  this.$cookies.set("status", "accepted", "transaction status");
                  this.statusCode = status_code;
                  clearInterval(interval);

                  // Confirm the completion of the transaction
                  const resp = await fetch(
                    `${this.baseURL}/v1/hosts/jungleworks/${this.transactionId}/complete`,
                    {
                      method: "PATCH",
                      headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                      },
                    }
                  );

                  if (!resp.ok) {
                    throw new Error(`HTTP error! status: ${resp.status}`);
                  }

                  const result = await resp.json();
                  const { status: finalStatus, url: { success } } = result;
                  this.urlSuccess = success;
                  this.transactionStatus = finalStatus;

                  this.currentStep += 1;
                  this.authloader = false;

                  // Validate URL and handle success redirect
                  if (this.isValidUrl(this.urlSuccess)) {
                    setTimeout(() => {
                      this.handleSuccessRedirect();
                    }, 3000);
                  }
                }

                // Handle rejected status
                if (status.replace(/\s/g, "") === "rejected") {
                  this.$cookies.set("status", "rejected", "transaction status");
                  this.statusCode = status_code;
                  this.transactionStatus = status;
                  this.currentStep += 1;
                  clearInterval(interval);
                }

                // Check for time limit expiration
                if (this.timeLimit <= this.timeElapsed) {
                  this.$cookies.set("status", "expired", "transaction status");
                  clearInterval(interval);
                }
              } catch (error) {
                console.error(error);
                clearInterval(interval); // Ensure the interval is cleared on error
              }
            }, 2000);
            break;
          }
          default: {
            console.log("No errand");
            break;
          }
        }
      }
    },
    isValidUrl(urlString) {
      const urlPattern = new RegExp(
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{2,63}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/i
      );
      return !!urlPattern.test(urlString);
    },
    async sendOtp() {
      const optValue = this.$refs.otpInput.otp.join("");
      this.otpCode = optValue;

      if (!this.otpCode) {
        useToast().error(this.$t("otpAlert"));
      } else {
        this.otpSent = true;
        const response = await fetch(
          `${this.baseURL}/v1/hosts/transactions`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              id: this.transactionId,
              type: "confirmation",
              service: this.serviceMethod,
              processor: this.proccessorMethod,
              otp: this.otpCode,
              parent_transaction_id: this.parentID,
            }),
          }
        );
        if (!response.ok) {
          useToast().error(`Error with confirmation`);
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        // const data = await response.json();
        const locationConfirm = await response.headers.get("Location");
        this.currentStep += 1;

        this.locationConfirmation = locationConfirm;

        this.startTimer();

        let interval = setInterval(async () => {
          const res1 = await fetch(`${this.baseURL}${locationConfirm}`, {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          });

          if (!res1.ok) {
            throw new Error(`HTTP error! status: ${res1.status}`);
          }

          const arr = await res1.json();

          const { status, status_code } = arr;
          //status == "rejected" ||

          if (status == "accepted") {
            // this.transactionStatus =status;
            this.statusCode = status_code;
            clearInterval(interval);

            //CONFIRM COMPLETE
            const resp = await fetch(
              `${this.baseURL}/v1/hosts/jungleworks/${this.transactionId}/complete`,
              {
                method: "PATCH",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            );
            if (!response.ok) {
              throw new Error(`Error with confirmation${response.status}`);
            }

            const finalResult = await resp.json();
            // this.finalData = finalResult;

            const { status } = finalResult;
            const {
              url: { failure, success },
            } = finalResult;
            this.urlFailure = failure;
            this.urlSuccess = success;
            this.transactionStatus = status;

            // if (status == "rejected") {
            //   this.currentStep += 1;
            //   this.authloader = false;
            // } else {
            this.currentStep += 1;
            this.authloader = false;

            const isValidUrl = (urlString) => {
              var urlPattern = new RegExp(
                /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{2,63}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/i
              ); // eslint-disable-line
              return !!urlPattern.test(urlString);
            };

            if (isValidUrl(this.urlSuccess)) {
              setTimeout(() => {
                this.handleSuccessRedirect();
              }, 3000);
            }
          } else {
            this.statusCode = status_code;

            this.transactionStatus = status;
            this.currentStep += 1;
            clearInterval(interval);
          }
          if (this.timeLimit <= this.timeElapsed){
                clearInterval(interval);
          }
        }, 5000);
      }
    },
    startTimer() {
      this.timerInterval = setInterval(() => {
        // Stop counting when there is no more time left
        if (++this.timeElapsed === this.timeLimit) {
          clearInterval(this.timerInterval);
        }
      }, 1000);
    },
    startTimerRef() {
      this.timerIntervalRef = setInterval(() => {
        if (this.timeElapsedRef < this.timeLimitRef) {
        this.timeElapsedRef++;
      } else {
        clearInterval(this.timerIntervalRef);
        // Optionally perform an action when the timer reaches the limit
        // For example: this.timerComplete1();
      }
      }, 1000);
    },

    stopTimer() {
      // this.timerInterval = setInterval(() => {
      //   // Stop counting when there is no more time left
      //   if (++this.timeElapsed === this.timeLimit) {
      //     clearInterval(this.timerInterval);
      //   }
      // }, 1000);
      this.timeElapsed = 0;
      this.timeLimit = 90;
    },
    startTimer1() {
      this.timerIntervalTimer1 = setInterval(() => {
        // Stop counting when there is no more time left
        if (++this.timeElapsed1 === this.timeLimit1) {
          clearInterval(this.timerIntervalTimer1);
        }
      }, 1000);
    },
  },
  mounted() {

    this.id = this.$route.query.id;
    this.nonce = this.$route.query.nonce;
    fetch(`${this.baseURL}/v1/hosts/payments/${this.id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((response) => {

        if (response.code === 404) {
          this.currentStep = 6;
          this.isLoading = false;
        } else {
          fetch(
            `${this.baseURL}/v1/hosts/payment-methods?id=${this.id}&nonce=${this.nonce}`,
            {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          )
            .then((response) => response.json())
            .then((response) => {
              const data = response;

              this.payment = data;

              const {
                transaction: { amount, reference_id, id, currency },
              } = data;

              this.transactionAmount = amount;
              this.transactionRefrenceId = reference_id;
              this.transactionId = id;
              this.transactionCurrency = currency;

              //get  merchant name
              const {
                merchant: { name, country },
              } = data;
              this.nameMerchant = name;
              this.country = country;

              //get payment merthods
              const { payment_methods } = data;
              this.paymentMethods = payment_methods;

              this.isLoading = false;

            })
            .catch((error) => {
              return console.log(error);
            });
        }
      })
      .catch((error) => console.log(error));
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
  background-color: #fff;
}

#app {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10;
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#C9DEF4", endColorstr="#F5CCD4", GradientType=1);
  overflow:auto;
}

#overlay {
  position: fixed;
  /* Sit on top of the page content */
  display: flex;
  /* Hidden by default */
  width: 100%;
  /* Full width (cover the whole page) */
  height: 100%;
  /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f5f5f5;
  /* Black background with opacity */
  z-index: 2;
  /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
  /* Add a pointer on hover */
  justify-content: center;
  align-items: center;
}

.loader {
  width: 48px;
  height: 48px;
  border: 3px solid #282a3a;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.reference-1 {
  border: 1px solid #282a3a;
  padding: .5rem;
  font-size: 1rem;
  font-weight: bold;
}

.reference-2 {
  border: 1px solid #282a3a;
  padding: .5rem;
}

.loader-btn {
  width: 20px;
  height: 20px;
  border: 3px solid #282a3a;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (min-device-width: 320px) {
  .modal-cancel {
    padding: 1rem;
    max-width: 250px;
  }

  .radio-toolbar input[type="radio"]:checked+label img {
    filter: grayscale(0%);
  }
}

@media only screen and (min-device-width: 390px) {
  .modal-cancel {
    max-width: 250px;
  }

  .payment-methods-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .radio-toolbar input[type="radio"]:checked+label img {
    filter: grayscale(0%);
  }
}

@media screen and (max-width: 767px) {
  .main{
    align-items: normal !important;
    flex-direction: unset  !important;
    justify-content: center  !important;
    margin-bottom: 0 !important;
  }

  .radio-toolbar {
    justify-content: flex-end !important;
  }

  .radio-toolbar label {
    width: 100%;
    position: absolute !important;
  }

  .grayscale {
    filter: grayscale(100%);
    padding-left: 0rem !important;
  }

  .radio-toolbar label {
    padding: 12px 5px;
  }
  

}

@media (min-device-width: 768px) {
  .container {
    width: 100%;
    max-width: 450px;
    margin: auto;
  }

  .payment-methods-container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .radio-toolbar label {
    padding: 12px 12px 10px 70px;
  }

  .radio-toolbar input[type="radio"]:checked+label img {
    filter: grayscale(0%);
  }

  .grayscale {
    filter: grayscale(100%);
    padding-left: 5rem !important;
  }
}

@media only screen and (min-device-width: 1281px) {
  .container {
    width: 100%;
    max-width: 450px;
    margin: auto;
  }

  .payment-methods-container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .radio-toolbar label {
    padding: 12px 12px 10px 70px;
  }

  .grayscale {
    filter: grayscale(100%);
    padding-left: 5rem;
  }
}

.form{
  border: 1px solid #ddd;
}

.form1,
.form2,
.form3,
.form4,
.not-found,
.form-redirect,
.complete {
  width: 100%;
  min-height: 620px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  
}

.description {
  font-size: 1rem;
  color: #282a3a;
}

.warning {
  color: #e30000;
  font-size: 0.8rem;
  padding: 0.8rem 1rem;
  margin-bottom: 1rem;
}

.main {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 50px;
}

.header-title {
  margin-bottom: 1rem;
  font-size: 1.1rem;
}

.med-body {
  height: 100%;
}

/*
STEP ONE
**/

.form1 {
  border-radius: 4px;
  background-color: #fff;
  text-align: center;
}

.header {
  padding: 0;
  width: 100%;
  margin: 0;
}

.header-amount {
  margin: 0;
  font-size: 1.4rem;
  font-weight: bold;
}

.header-entity {
  margin: 0;
  font-size: 1rem;
  font-weight: bold;
}

.header .header-amount-label {
  margin: 0rem 0rem 0 0rem;
  font-size: 0.8rem;
  color: #282a3a;
  opacity: 0.7;
}

.header .header-entity-label {
  margin: .5rem 0rem 0 0rem;
  font-size: 0.8rem;
  color: #282a3a;
  opacity: 0.7;
}

.header .header-order-id-label {
  margin: .5rem 0rem 0 0rem;
  font-size: 0.8rem;
  color: #282a3a;
  opacity: 0.7;
}

.header .header-order-id {
  margin: 0;
  font-size: 1rem;
  font-weight: bold;
}

.header .header-merchant-label {
  margin: 0rem 0rem 0 0rem;
  font-size: 0.8rem;
  color: #282a3a;
  opacity: 0.7;
}

.header-order-intrution {
  font-size: 0.8rem;
  color: #282a3a;
  opacity: 0.7;
}

.header .header-merchant-name {
  margin: 0;
  font-size: 1rem;
  font-weight: bold;

}

.header .header-reference-label {
  margin: 0rem 0rem 0 0rem;
  font-size: 0.8rem;
  color: #282a3a;
  opacity: 0.7;

}


.header .header-reference {
  font-size: 1rem;
  font-weight: bold;
  color: #282a3a;
  margin: 0rem 0rem 0 0rem;
}

.header .header-order-id-label {
  margin: 1rem 0rem 0 0rem;
  font-size: 0.8rem;
  color: #282a3a;
  opacity: 0.7;
}

.header .header-order-id {
  font-size: 1rem;
  font-weight: bold;
  color: #282a3a;
  margin: 0;
}
.header-content{
  position: relative;
  padding: .5rem 0rem 0rem 0rem;
  width: 100%;

}
.header-content .group-1{

  height: 1.5rem;
}
.header-content .group-2{

  height: 1.5rem;
}
.header-content .group-3{

  height: 1.5rem;
}
.payment-methods {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.payment-methods-title {
  text-align: left;

  margin: 0.2rem 0;
  font-size: 0.7rem;
  opacity: 0.7;
  padding: 0.2rem 0;
}

.payment-methods-card {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-bottom: 5px;
}

.radio-toolbar {
  height: 3rem;
  position: relative;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 3px;
  border: 1px solid #c5c7c7;
  opacity: 0.9;
}

.radio-toolbar input[type="radio"] {
  opacity: 0;
  position: absolute;
  z-index: 2;
  height: 2.5rem;
  cursor: pointer;
  appearance: none;
}

.radio-toolbar label {
  display: flex;
  text-align: left;
  font-size: 16px;
  border-radius: 4px;
  height: 5vh;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  z-index: -2;
  position: relative;
}

.radio-toolbar label img {
  width: 8rem;
  height: 2.4rem;
  object-fit: contain;
}

.express {
  height: 1.3rem !important;
}

.grayscale {
  filter: grayscale(100%);
  padding-left: 5rem;
}

.radio-toolbar input[type="radio"]:checked+label+div {
  background-color: #28c937;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  opacity: 1;
}

.radio-toolbar input[type="radio"]:checked+label+div img {
  opacity: 1;
}

.radio-toolbar input[type="radio"]:checked+label img {
  filter: grayscale(0%);
}

.custom-checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.7rem;
  height: 1.7rem;
  border-radius: 50%;
  opacity: 0;
  background-color: #282a3a;
  margin-right: 1rem;
}

.custom-checkbox img {
  width: 0.8rem;
  height: 0.8rem;
  padding: 0.5rem;
  opacity: 0;
}

.footer {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0.5rem;
  bottom:0;
}

.btn {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 4px;
  color: #fff;
  background-color: #282a3a;
  cursor: pointer;
  font-size: 16px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

/**
STEP TWO
*/
.form2 {
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  position: relative;
}

.header {
  padding: 0rem 0rem 0rem 0rem;
}

.number {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.2rem;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 2rem;

  /* padding: 0 .5rem; */
}

.input-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.input-container img {
  width: 6rem;
  margin-bottom: 1rem;
}

.input-container .header-amount {
  text-align: center;
  margin: 0.2rem 0;
}

.input {
  width: 98%;
  font-size: 1.1rem;
  line-height: 12px;
  border: none;
  background: #f5f5f5;
  padding: 0.7rem 0.5rem;
  text-align: center;
  color: #282a3a;
  padding-left: 4rem;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.input:focus {
  outline: none;
}

.input::placeholder {
  color: #282a3a;
  opacity: 0.3;
  width: 100%;
  text-align: center;
}

/**
MRTHOD INSTRUTION
*/
.instrution {
  /* position: absolute; */
  width: 100%;
  font-size: 11px !important;
  left: 0rem;
  top: 2.5rem;
  text-align: center !important;
  justify-content: center;
}

.instrution span {
  font-size: 11px !important;
  text-transform: capitalize;
  font-weight: bold;
}

.footer2 {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-direction: row;
  justify-content: space-between;
}

.btn-backward {
  /* width: 3.6rem; */
  margin-right: 20px;
  text-align: center;
  border-radius: 4px;
  color: #282a3a;
  background-color: #fff;
  border: 1px solid #282a3a;
  cursor: pointer;
  font-size: 16px;
}

.btn-backward:hover {
  background-color: #282a3a;
  color: #fff;
}

.btn-forward {
  /* width: 3.6rem; */
  text-align: center;
  border-radius: 4px;
  border: 1px solid #282a3a;
  color: #fff;
  background-color: #282a3a;
  cursor: pointer;
  font-size: 16px;
}

/**
STEP #3
*/
.form3 {
  border-radius: 4px;
  background-color: #fff;
  align-items: center;
  text-align: center;
}

.header {
  padding: 0rem 0 0rem 0rem;
  margin: -1rem 0;
}

.header span {
  font-size: 12px;
  color: #282a3a;
  margin: 0;
}

.opt-container {
  padding: 0rem 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 1rem;
}

.opt-container img {
  width: 7rem;
  margin: 0;
}

.opt-container #timer {
  margin: 1rem;
  height: 1rem;
  font-size: 1rem;
  font-weight: 300;
}

.otp-input {
  width: 1rem;
  height: 0.6rem;
}


.footer3 {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-direction: row;
  justify-content: space-between;
}

.btn-backward {
  width: 100%;
  text-align: center;
  border-radius: 4px;
  color: #282a3a;
  background-color: #fff;
  border: 1px solid #282a3a;
  cursor: pointer;
  font-size: 16px;
}

.btn-backward:hover {
  background-color: #282a3a;
  color: #fff;
}

/*ripple effect*/
.ripple {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.ripple:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform 0.5s, opacity 2s;
}

.ripple:active:after {
  transform: scale(0, 0);
  opacity: 0.3;
  transition: 0s;
}

.btn-forward {
  width: 100%;
  text-align: center;
  border-radius: 4px;
  color: #fff;
  background-color: #282a3a;
  cursor: pointer;
  font-size: 16px;
  border: 1px solid #282a3a;
}

/**

STEP FOUR*/

.form4 {
  border-radius: 4px;
  background-color: #fff;
  align-items: center;
  text-align: center;
}

.header {
  padding: 0rem 0 0rem 0rem;
  margin: 0;
}

.header span {
  font-size: 12px;
  color: #282a3a;
}
.countdown-container{
  padding-top: 4rem;
}

.countdown-container p {
  margin: 0;
}

.footer-countdown {
  width: 100%;
  padding-bottom: 2rem;
}

.footer-countdown p {
  margin: 0.2rem;
}

.complete {
  border-radius: 4px;
  background-color: #fff;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
}

.complete-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.complete img {
  width: 7rem;
}

.footerComplete {
  margin-top: 5rem;
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  justify-content: space-between;
  align-items: center;
}

.btn-cancel {
  /* width: 3.6rem; */
  margin-right: 10px;
  text-align: center;
  border-radius: 4px;
  color: red;
  background-color: #fff;
  border: 1px solid red;
  cursor: pointer;
  font-size: 16px;
}

.btn-retry {
  width: 100%;
  text-align: center;
  border-radius: 4px;
  color: #fff;
  background-color: #282a3a;
  cursor: pointer;
  font-size: 16px;
  border: 1px solid #282a3a;
}

/**
*CANCEL MODAL
*/
.btn-yes {
  width: 100%;
  text-align: center;
  border-radius: 4px;
  color: #fff;
  background-color: #282a3a;
  cursor: pointer;
  font-size: 16px;
  border: 1px solid #282a3a;
}

.modal-cancel-title {
  font-size: 1.2rem;
  padding-bottom: 2rem;
}

.modal-cancel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

.modal-cancel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 3rem;
  border-radius: 4px;
}

.modal-controls {
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  justify-content: space-between;
  align-items: center;
}

.btn-no {
  width: 100%;
  text-align: center;
  border-radius: 4px;
  color: #282a3a;
  background-color: #fff;
  border: 1px solid #282a3a;
  cursor: pointer;
  font-size: 16px;
}

.btn-no:hover {
  background-color: #282a3a;
  color: #fff;
}

/**
*END
*/

/**
*REDIRECT
 */
.form-redirect {
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: #fff;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.counter-title {
  font-size: 1.2rem;
}

.counter {
  font-size: 2.4rem;
  font-weight: bold;
}

/**
 ENND
  */

.rejected {
  border-radius: 4px;
  width: 22rem;
  height: 27rem;
  background-color: #fff;
  display: grid;
  grid-template-rows: 0.5fr 2fr 0.5fr;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.rejected-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rejected img {
  width: 7rem;
}

/**
NOT FOUND FORM
 */
.not-found {
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.not-found img {
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
}

.not-found p {
  margin: 1rem;
}

.not-exist {
  border-radius: 4px;
  width: 22rem;
  height: 27rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.not-exist img {
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
}

.not-exist p {
  margin: 1rem;
}

.internal-error {
  border-radius: 4px;
  width: 22rem;
  height: 30rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.internal-error img {
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
}

.internal-error p {
  margin: 1rem;
}

/**
  */
.checkbox-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}

input[type="radio"] {
  height: 5rem;
  width: 100%;
  background: #e8e8e8;

  /* The outline will be the outer circle */
  outline: none;
}

/**
OTP
*/

.input-container {
    position: relative;
    width: 300px; /* Adjust width as needed */
    margin: 0 auto; /* Center the input */
  }

  .input-text {
    position: absolute;
    left: 5px; /* Adjust left position as needed */
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px; /* Adjust font size as needed */
    color: #555; /* Adjust color as needed */
    pointer-events: none; /* Prevent text from being selected */
    border-right: #555;
  }

  .input-field {
    width: 100%;
    height: 40px;
    padding-left: 70px; /* Ensure input content doesn't overlap with the text */
    padding-top: 4px;
    box-sizing: border-box; /* Include padding in width */
    font-size: 20px; /* Adjust font size as needed */
    border: 1px solid #ccc; /* Adjust border as needed */
    border-radius: 4px; /* Adjust border radius as needed */
    outline: none; /* Remove default focus outline */
    text-align: center;
    color:#555;
  }

/**

INPUT*/
.otp-input {
  padding: 1rem;
  width: 1rem;
  text-align: center;
  background-color: #f5f5f5;
  border: 0;
  border-bottom: 1px solid #ddd;
  font-size: 1.1rem;
  margin: 0rem 0.2rem;
}

.otp-input:focus {
  outline: none;
}

/** */
.success-p {
  padding: 0.5rem 0;
}

[type="radio"]:checked::before,
[type="checkbox"]:checked::before {
  content: "";
  width: 2rem;
  height: 2rem;
  background-color: #282a3a;
  position: absolute;
  top: 2px;
  left: 2px;
}

.empty {
  position: fixed;
  /* Sit on top of the page content */
  display: flex;
  /* Hidden by default */
  width: 100%;
  /* Full width (cover the whole page) */
  height: 100%;
  /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#C9DEF4", endColorstr="#F5CCD4", GradientType=1);
  z-index: 2;
  /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
  /* Add a pointer on hover */
  justify-content: center;
  align-items: center;
}

.empty-card {
  border-radius: 4px;
  width: 20rem;
  height: 20rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.empty-card img {
  width: 7rem;
  margin: 0.5rem 0;
}

.empty-card p {
  margin: 0;
}

.table-container {
  overflow-x: auto;
  display: flex;
  justify-content: center;
}

table {
  width: auto;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  writing-mode: horizontal;  /* Texto na horizontal à esquerda */
  transform: rotate(0deg);  /* Ajusta a orientação do texto */
  white-space: nowrap;        /* Evita que o texto quebre em várias linhas */
  width: 20px;                /* Ajusta a largura conforme necessário */
  /*background-color: #87CEEB;*/
}

td:first-child {
  font-weight: bold;
}



.tooltip-text {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 115%; /* Posiciona a dica de ferramenta acima do texto */
  left: 70%;
  margin-left: -60px; /* Centraliza a dica de ferramenta sobre o texto */
  opacity: 0;
  transition: opacity 0.3s;
}



</style>
