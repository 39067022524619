import { createI18n } from "vue-i18n";
// import pluralRules from "./rules/pluralization"
// import numberFormats from "./rules/numbers.js"
// import datetimeFormats from "./rules/datetime.js"

function getUserLocale() {
  const locale = window.navigator.language || window.navigator.userLanguage;

  locale.split("-")[0];
  if (locale.split("-")[0] === "pt") {
    return "pt";
  } else {
    return "en";
  }
}

const messages = {
  en: {
    title: "Wiza Payments",
    description: "Payment methods",
    submit: "SUBMIT",
    next: "NEXT",
    amount: "Amount",
    merchant: "Merchant",
    reference: "Reference",
    back: "BACK",
    retry: "RETRY",
    cancel: "CANCEL",
    redirectMessage:
      "You are going to be redirected to merchant website or app in:",
    yes: "YES",
    no: "NO",
    unknown: "Unknown Error",
    warning: "Do not refresh this page while waiting for payment confirmation",
    waiting: "Waiting confirmation",
    purchaseMsn: "Payment completed successfully",
    cellphoneAlert: "Enter the cell phone number",
    paymentMethodAlert: "Select a payment method",
    otpAlert: "Enter OTP code",
    transactionNotFound: "Transaction not found",
    paymentmethodTitle: "Choose Payment Method",
    message: {
      2000: "Successful",
      3000: "Invalid or disabled account",
      3001: "Insufficient funds",
      3002: "Rejected by the customer",
      3003: "Request Timed out",
      3004: "Invalid Confirmation",
      3005: "Invalid PIN",
      3006: "Amount is lower or higher than allowed limit",
      4000: "Processor Error",
      4001: "Merchant Not Authorized",
      4003: "Processor Unavailable",
      4004: "Transaction Expired",
      9000: "Unknown Error",
    },
    paymentInstrutionPart1:"For this payment method you should have",
    paymentInstrutionPart2:"associated to this phone you are using.",
    referenceIntrution:"Use your bank application, multicaixa express app, ATM or payment terminal to make the payment for this order.",
    entity:"Entity",
    orderID:"Order ID",
    orderTimeExpired:"Expired Time",
    referenceID:"Reference"
  },



  pt: {
    title: "Wiza Pagamentos",
    description: "Métodos de pagamento",
    next: "SEGUINTE",
    submit: "ENVIAR",
    amount: "Valor",
    merchant: "Comerciante",
    reference: "Referência",
    back: "VOLTAR",
    retry: "TENTAR NOVAMENTE",
    cancel: "CANCELAR",
    redirectMessage:
      "Você será redirecionado para o site ou aplicativo do comerciante em:",
    yes: "SIM",
    no: "NÂO",
    unknown: "Erro Desconhecido",
    warning:
      "Não atualize esta página enquanto aguarda a confirmação do pagamento",
    waiting: "Aguardando confirmação",
    purchaseMsn: "Pagamento concluído com sucesso",
    cellphoneAlert: "Digite o número do celular",
    paymentMethodAlert: "Selecione um método de pagamento",
    otpAlert: "Insira o código OTP",
    transactionNotFound: "Transação não encontrada",
    paymentmethodTitle: "Escolha o método de pagamento",
    message: {
      2000: "Sucesso",
      3000: "Conta inválida ou desactivada",
      3001: "Saldo Insuficiente",
      3002: "Rejeitado pelo cliente",
      3003: "Tempo expirado",
      3004: "Confirmação Inválida",
      3005: "PIN Inválido",
      3006: "Valor é menor ou maior do limite permitido",
      4000: "Erro no Processador",
      4001: "Comerciante Não Autorizado",
      4003: "Processador Indisponível",
      4004: "Transação Expirada",
      9000: "Erro Desconhecido",
    },
    paymentInstrutionPart1:"Para este método de pagamento deverá ter",
    paymentInstrutionPart2:"associado ao telefone que pretende usar.",
    referenceIntrution:"Use o Aplicativo do seu banco, aplicativo do multicaixa express, ATM ou terminal de pagamento para efectuar o pagamento para este pedido.",
    entity:"Entidade",
    orderID:"ID do Pedido",
    orderTimeExpired:"Tempo Expirado",
    referenceID:"Referência"
  },
};

export default createI18n({
  locale: getUserLocale(),
  fallbackLocale: "en",
  legacy: false,
  messages,
});
