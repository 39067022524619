<template>
  <div class="cdt-container">
    <p><strong>{{ timeLeftString }}</strong></p>
  </div>
</template>

<script>
export default {
  name: "OtpTimer",
  props:{
    elapsed1:{
      type:Number,
      required: true
    },
    limit1:{
      type:Number,
      required: true
    }
  },
  computed:{
    // e.g. timeLeft of 100 -> '01:40'
    timeLeftString() {
      const timeLeft = this.timeLeft;
      const minutes = Math.floor(timeLeft / 60);
      const seconds = timeLeft % 60;
      return `${this.padToTwo(minutes)}:${this.padToTwo(seconds)}`;
    },
    timeLeft() {
      return this.limit1 - this.elapsed1;
    },
  },
  methods:{
    padToTwo(num){
       //e.g 4->04
       return String(num).padStart(2, '0');
    }
  }
};
</script>

<style>
.cdt-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

</style>
