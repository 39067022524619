<template>
    <div class="inputWithIcon">
      <input
          ref="inputRef"
          type="text"
          maxlength="14"
          v-model="maskedPhoneNumber"
          readonly
        />      
        <div class="icons-clip fa-lg fa-fw">
          <div v-if="!copied" @click="copyToClipboard"><font-awesome-icon icon="fa-solid fa-clone" /></div>
          <div v-if="copied" class="copied-message"><font-awesome-icon icon="fa-solid fa-check" /></div>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "InputCopyClipBoard",
    props: {
      phoneNumber: {
        type: String,
        default: "", // Default value if not provided
      },
    },
    data() {
      return {
        copied: false,
        maskedPhoneNumber: "",
      };
    },
    watch: {
      phoneNumber(newValue) {
        // Watch for changes in phoneNumber and apply formatting
        this.formatPhoneNumber(newValue);
      },
    },
    mounted() {
      // Apply initial formatting when the component is mounted
      this.formatPhoneNumber(this.phoneNumber);
    },
    methods: {
      copyToClipboard() {
        const inputElement = this.$refs.inputRef;
        inputElement.select();
        document.execCommand("copy");
        // Optionally, you can provide user feedback, e.g., show a tooltip or message
        this.copied = true;
        setTimeout(() => {
          this.copied = false;
        }, 1800);
      },
      formatPhoneNumber(value) {
        // Remove non-numeric characters
        let phoneNumber = value.replace(/\D/g, '');

        // Apply the phone number mask
        if (phoneNumber.length >= 8) {
          phoneNumber = phoneNumber.replace(/(\d{9})/, '$1');
        }

        // Update the data property
        this.maskedPhoneNumber = phoneNumber;
      },
    },
  };
  </script>
  
  <style scoped>
  .copy-to-clipboard {
    align-items: center;
    justify-content: center;
  }
  
  input[type="text"] {
  width: 100%;
  border: 1px solid #aaa;
  border-radius: 4px;
  margin: 8px 0;
  outline: none;
  padding: 8px;
  box-sizing: border-box;
  transition: 0.3s;
  font-size: 1.2rem;
  padding-top: 11px;
  text-align: center;
}

input[type="text"]:focus {
  /*border-color: dodgerBlue;*/
  border-color: #aaa;
}

.inputWithIcon {
  position: relative;
  margin: 0 140px;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .inputWithIcon {
    margin: 0 70px;
  }
}

.inputWithIcon .icons-clip{
  position: absolute;
  right: 0;
  top: 10px;
  padding: 9px 5px;
  color: #aaa;
  transition: 0.3s;
}

.inputWithIcon input[type="text"]:focus + .icons-clip {
  /*color: dodgerBlue;*/
  color: #aaa;
}
.inputWithIcon.inputIconBg i {
  background-color: #aaa;
  color: #fff;
  padding: 9px 4px;
  border-radius: 4px 0 0 4px;
}

.inputWithIcon.inputIconBg input[type="text"]:focus + .icons-clip {
  color: #fff;
  /*background-color: dodgerBlue;*/
  background-color: #aaa;
}

.fa-check{
  color: green;
}
  </style>