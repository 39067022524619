import { createApp } from 'vue'
import i18n from './i18n'
import App from './App.vue'
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import VueCookies from 'vue-cookies'

import {createRouter, createWebHistory} from 'vue-router'
import HomePage from '@/views/HomePage.vue'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { faClone } from '@fortawesome/free-solid-svg-icons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faUserSecret)
library.add(faClone)
library.add(faCheck)

/* add font awesome icon component */

const router = createRouter(
   {
    history: createWebHistory(),
    routes:[
       {path:'/', component: HomePage} 
    ]
   }
);

//const baseURL = process.env.VUE_APP_BASE_URL;

const app = createApp(App).use(i18n).use(Toast).use(router)
app.component('font-awesome-icon', FontAwesomeIcon)
app.use(VueCookies, { expires: '7d'})

app.mount('#app')